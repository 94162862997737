import Image1 from "../../assets/project1.jpg";
import Image2 from "../../assets/project2.jpg";
import Image3 from "../../assets/project3.jpg";
import Image4 from "../../assets/project4.jpg";
import Image5 from "../../assets/project5.jpg";
import Image6 from "../../assets/project6.jpg";
import Image7 from "../../assets/project7.jpg";
// import Image8 from "../../assets/project8.jpg";
// import Image9 from "../../assets/project9.jpg";

const data = [
  {
    id: 1,
    category: "uxui",
    image: Image1,
    title: "SukjaiTrip (UXUI)",
    desc: "Choose your local travel lifestyle in Thailand based on your favorite activities",
    demo: "https://drive.google.com/file/d/1WZqNuwBTw5p0zZWFbxCxTj98_4-W6Kc7/view?usp=drive_link",
    // github: "https://drive.google.com/drive/u/0/home",
  },
  {
    id: 2,
    category: "frontend",
    image: Image2,
    title: "ebuy (Frontend)",
    desc: "Are you looking for timeless treasures Visit collectibles and follow your passion",
    demo: "https://www.canva.com/design/DAF0QX4PVcM/rYOuWlOWln7i2gXP8A_8zw/edit?utm_content=DAF0QX4PVcM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    github: "https://github.com/TimeRuchutrakool/ebuy-react-project.git",
  },
  {
    id: 3,
    category: "frontend",
    image: Image3,
    title: "STEP ART(Frontend)",
    desc: "we offer Art & Craft Classes, Leisure Learning Courses and all ages and abilities",
    demo: "https://www.canva.com/design/DAF2wPs_3BU/fX-RW1GSC7jQR585ANw7yg/edit?utm_content=DAF2wPs_3BU&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    github: "https://github.com/paintt23/stepart-react.git",
  },
  {
    id: 4,
    category: "uxui",
    image: Image4,
    title: "ebuy(UXUI)",
    desc: "Are you looking for timeless treasures Visit collectibles and follow your passion",
    demo: "https://www.canva.com/design/DAF0QX4PVcM/rYOuWlOWln7i2gXP8A_8zw/edit?utm_content=DAF0QX4PVcM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    // github:
    //   "https://www.canva.com/design/DAF0QX4PVcM/rYOuWlOWln7i2gXP8A_8zw/edit?utm_content=DAF0QX4PVcM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
  },
  {
    id: 5,
    category: "uxui",
    image: Image5,
    title: "STEP ART(UXUI)",
    desc: "we offer Art & Craft Classes, Leisure Learning Courses for all ages and abilities",
    demo: "https://www.canva.com/design/DAF2wPs_3BU/fX-RW1GSC7jQR585ANw7yg/edit?utm_content=DAF2wPs_3BU&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    // github: "https://github.com/egattor",
  },
  {
    id: 6,
    category: "backend",
    image: Image6,
    title: "STEP ART (backend)",
    desc: "we offer Art & Craft Classes, Leisure Learning Courses for all ages and abilities",
    demo: "https://www.canva.com/design/DAF2wPs_3BU/fX-RW1GSC7jQR585ANw7yg/edit?utm_content=DAF2wPs_3BU&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    github: "https://github.com/paintt23/stepart-api.git",
  },
  {
    id: 7,
    category: "backend",
    image: Image7,
    title: "ebuy (backend)",
    desc: "Are you looking for timeless treasures Visit collectibles and follow your passion",
    demo: "https://www.canva.com/design/DAF0QX4PVcM/rYOuWlOWln7i2gXP8A_8zw/edit?utm_content=DAF0QX4PVcM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    github: "https://github.com/TimeRuchutrakool/ebuy-express-project.git",
  },
  // {
  //   id: 8,
  //   category: "backend",
  //   image: Image8,
  //   title: "Project Title Eight (Backend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  // },
  // {
  //   id: 9,
  //   category: "frontend",
  //   image: Image9,
  //   title: "Project Title Nine (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  // },
];

export default data;
